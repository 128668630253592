.button {
  color: inherit;
  background-color: hsl(var(--preview-color));
  text-transform: uppercase;
}

.major {
  font-weight: bold;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
}

.button > span > span {
  margin: 0 10px;
}

.colored > svg {
  color: hsl(var(--blue));
}

.colored > span > span:first-child {
  color: hsl(var(--blue));
}

.colored > span > span:nth-child(2) {
  /* color: hsl(var(--pink)); */
  color: hsl(var(--green));
}

.colored > span > span:last-child {
  /* color: hsl(var(--light-blue)); */
  color: hsl(var(--red));
}
