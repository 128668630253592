.items {
  display: flex;
  margin: -0.5rem;
  flex-wrap: wrap;
}

.item {
  margin: 0.5rem;
  flex: 0 0 auto;
}

.item button {
  padding: 0.3rem 0.7rem;
  background-color: hsl(var(--preview-color));
  border-radius: var(--border-radius);
}
