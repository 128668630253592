.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  border-radius: var(--border-radius);
}

.button > span:only-child {
  margin: 0.2em 0.75em;
}

.button > span:not(:only-child) {
  margin: 0.2em 0.75em 0.2em 0.2em;
}

.button > svg:not(:only-child) {
  margin-right: 0.25em;
  margin-left: 0.5em;
}

.button > svg:only-child {
  margin: 0.13em;
}

.red {
  background-color: hsl(var(--red));
}

.blue {
  background-color: hsl(var(--blue));
}

.green {
  background-color: hsl(var(--green));
}

.dark {
  background-color: hsl(var(--dark));
  color: hsl(var(--dark-white));
}
