.item:not(:last-child) {
  margin-bottom: 1rem;
}

.item p {
  margin-bottom: 0.6rem;
}

.item input {
  padding: 0.6rem;
  background-color: hsl(var(--text-field-color));
}
