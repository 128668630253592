.main {
  max-width: 1230px;
  padding: 15px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}

.main > section {
  margin: var(--spacing) 0;
}

.main > section > h2 {
  margin-bottom: var(--half-spacing);
  font-size: 1.2rem;
  font-weight: bold;
}

.footerButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.footerButtons > button {
  margin: 0.5rem;
}

.infoMsg {
  opacity: 0.8;
}

.infoMsg kbd {
  background-color: hsl(var(--text-field-color));
  border-radius: 3px;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 4px 6px;
  white-space: nowrap;
}
