.condition {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.condition > button {
  margin: calc(1rem + var(--half-spacing)) 2px 0px 3px;
  font-size: 1rem;
}

.condition > button::after {
  content: "";
  position: absolute;
  top: calc(3.5rem + var(--half-spacing));
  bottom: var(--half-spacing);
  width: 2px;
  background-color: hsl(var(--text-field-color));
  pointer-events: none;
}

.items {
  flex: 1 1 auto;
}

.root {
  font-size: 0;
}

.item {
  display: flex;
  align-items: flex-start;
  margin: var(--half-spacing) 0;
  font-size: 1rem;
}

.item > h6 {
  flex: 0 0 70px;
  margin-right: var(--half-spacing);
  margin-top: 1rem;
  font-weight: bold;
  text-align: end;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.item:first-child > h6 {
  color: hsl(var(--blue));
}

.item:nth-child(2) > h6 {
  /* color: hsl(var(--pink)); */
  color: hsl(var(--green));
}

.item:last-child > h6 {
  /* color: hsl(var(--light-blue)); */
  color: hsl(var(--red));
}

.item > div {
  flex: 1 1 auto;
}

@media screen and (max-width: 767px) {
  .item {
    flex-direction: column;
    align-items: stretch;
    margin: 0;
  }

  .item:last-child {
    margin-bottom: 1rem;
  }

  .item > h6 {
    text-align: start;
    flex: 0 0 auto;
    margin: 1rem;
  }

  .condition > button {
    margin: var(--half-spacing) 2px 0px 3px;
  }
}

/* Transition styles */
.enter {
  transition:
    opacity 0.3s linear,
    transform 0.3s ease-out;
}

.enterFrom {
  opacity: 0;
  transform: scale(0.6) translateY(-50%);
}

.enterTo {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.leave {
  transition:
    opacity 0.3s linear,
    transform 0.3s cubic-bezier(0.87, -0.25, 0.85, 0.93);
}

.leaveFrom {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.leaveTo {
  opacity: 0;
  transform: scale(0.6) translateY(-50%);
}
