.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: var(--spacing);
  width: 100%;
  height: 100%;
  inset: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: hsl(0 0% 0% / 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay > div {
  position: relative;
  max-height: 90%;
}

.container {
  position: relative;
  padding: var(--spacing);
  background-color: hsl(var(--bg-color));
  border-radius: var(--border-radius);
}

.close {
  position: absolute;
  right: -0.7rem;
  top: -0.7rem;
}
