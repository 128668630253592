.container {
  position: relative;
  z-index: 1;
}

.backdrop {
  position: absolute;
  padding: 1rem;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  background-color: hsl(var(--text-field-color));
  z-index: 2;
  font-size: 1rem;
  color: transparent;
  pointer-events: none;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.backdrop mark {
  background-color: hsl(var(--var-name-color));
  border-radius: 3px;
  color: transparent;
}
