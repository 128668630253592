.dialog {
  text-align: center;
}

.dialog h3 {
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.buttons {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.buttons > button {
  margin: 0.5rem;
}
