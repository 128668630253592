@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

body {
  background-color: hsl(var(--bg-color));
  color: hsl(var(--main-text-color));

  /* fix page jerking when scrollbar disappears
    https://twitter.com/chriscoyier/status/544159988701495296
  */
  margin-left: calc(100vw - 100%);
}

.overflow-hidden {
  overflow: hidden;
}

button:active {
  filter: brightness(0.9);
}

button:focus-visible {
  outline: 2px solid hsl(var(--main-text-color));
  outline-offset: 1px;
}

/* used in components: Textarea (TemplateEditor); VariableAssigner (Preview); CommandPalette (TemplateEditor) */
.text-field {
  padding: 1rem;
  width: 100%;
  min-width: 250px;
  border-radius: var(--border-radius);
  color: hsl(var(--main-text-color));
  outline: none;
}

.text-field::placeholder {
  color: hsl(var(--main-text-color) / 0.35);
}

.text-field:focus {
  box-shadow: 0 0 0 2px hsl(var(--main-text-color) / 0.5);
}
